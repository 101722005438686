import useCallableFunction from "@/hooks/useCallableFunction";

export default class Transcription {
  constructor(obj) {
    this.processed = obj.processed || false;
    this.billed_seconds = obj.billed_seconds || 0;
    this.dateUpload = obj.dateUpload || "";
    this.processedSeconds = obj.processedSeconds || 0;
    this.filename = obj.filename || "";
    this.overtalkMetrics = obj.overtalkMetrics || [];
    this.sentimentMetrics = obj.sentimentMetrics || [];
    this.talkTimeMetrics = obj.talkTimeMetrics || [];
    this.utterances = obj.utterances || [];
    this.summarization = obj.summarization || "";
    this.observations = obj.observations || [];
    this.id = obj.id || null;
  }

  get() {
    return { ...this };
  }

  static async getTranscriptionsInADay(
    date,
    userId,
    page,
    itemsPerPage,
    filters
  ) {
    try {
      const obj = {
        date,
        userid: userId,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        filters,
      };

      const getTrancriptions = useCallableFunction(
        "getDailyTranscriptions/get"
      );
      const result = await getTrancriptions(obj);
      if (!result.data) return null;
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getDayCount(date, userId) {
    try {
      const obj = {
        date,
        userid: userId,
      };

      const count = useCallableFunction("getDailyTranscriptions/count");
      const result = await count(obj);

      if (!result.data) return null;
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getResultById(date, userId, uid) {
    try {
      const obj = {
        date,
        userid: userId,
        uid,
      };

      const getResult = useCallableFunction("getDailyTranscriptions/result");
      const result = await getResult(obj);

      if (!result.data) return null;
      return result.data;
    } catch (err) {
      console.log(err);
    }
  }
}
